import * as React from "react"

import { useEffect, useState } from "react"
import {Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./support.scss"
import { Helmet } from "react-helmet"

const SupportUsPage = () => {
  const addScript = ({ src, id, el, attr, onLoad }) => {
    const existing = document.getElementById(id);
    if (existing) {
      return existing;
    } else {
      const script = document.createElement("script");
      script.src = src;
      script.id = id;
      for(let k in attr){
        script.setAttribute(k,attr[k])
      }
      script.async = true;
      script.onload = () => {
        if (onLoad) {
          onLoad();
        }
      };
      el.appendChild(script);
      return script;
    }
  };


  useEffect(()=>{


    // addScript({
    //   src: `https://cdn.razorpay.com/static/widget/subscription-button.js`,
    //   id: "razor-subscribe-script",
    //   el: document.getElementById("frmRzrSubscription"),
    //   attr:{
    //     "data-subscription_button_id":"pl_J9TBC6RYhUsJto",
    //     "data-button_theme":"brand-color"
    //   },
    //   onLoad: () => {
    //     console.log("subscribe script loaded!");
    //   },
    // });
    // addScript({
    //   src: `https://checkout.razorpay.com/v1/payment-button.js`,
    //   id: "razor-donate-script",
    //   el: document.getElementById("frmRzrSubscription"),
    //   attr:{
    //     "data-payment_button_id":"pl_J9T2H22fsUBR08",
    //     "data-button_theme":"brand-color"
    //   },
    //   onLoad: () => {
    //     console.log("donate script loaded!");
    //   },
    // });

  },[])

  // <form><script src="https://cdn.razorpay.com/static/widget/subscription-button.js"  async> </script> </form>
  //<form><script src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_J9T2H22fsUBR08" async> </script> </form>
  return (
    <Layout>
      <Seo title="Support Us" />
      <div className="donate">
        <h1>Your contribution matters</h1>
        <p>Your contribution will help us make better content, travel more places and bring them to you. </p>
        <form id="frmRzrSubscription" ></form>
        <form id="frmRzrButton" ></form>


        <a href="https://www.patreon.com/visa2explore" target="_blank">Donate on Patreon</a>


      </div>
    </Layout>)
}

export default SupportUsPage

